<template>
  <div id="order">
    <v-row>
      <v-col cols="12">
        <label class="subtitle-2 ml-3 secondary--text">Observações</label>
        <ckeditor
          :disabled="!showEditOrders"
          v-model="order.observation_order"
          :readonly="is_disabled"
          id="richText"
          :config="editorConfig"
          rounded
          dense
          outlined
        />
      </v-col>
      <v-col cols="12">
        <label class="subtitle-2 ml-3 secondary--text">Observações internas</label>
        <ckeditor
          :disabled="!showEditOrders"
          v-model="order.observation_intern"
          :readonly="is_disabled"
          id="richText"
          :config="editorConfig"
          rounded
          dense
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    valid: Boolean,
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    is_disabled: null,
    editorConfig: {
      toolbar: [['Bold', 'Italic', 'Strike', 'Styles', 'Format']],
    },
  }),
  computed: {
    ...mapGetters('comercial', ['showEditOrders']),
  },
  methods: {},
  created() {
    this.is_disabled = this.$cookies.get('profile') == 5 ? true : false
  },
}
</script>
<style lang="scss">
#order .cke_chrome {
  border-radius: 30px;
  border: 1px solid #aaa !important;
  transition: all 0.2s;

  &:hover {
    border: 1px solid #555 !important;
  }
}

#order .cke_inner {
  background: none !important;
}

#order .cke_top {
  border-radius: 30px 30px 0 0;
}

#order .cke_bottom {
  background: none !important;
  padding: 6px 19px !important;
}
</style>
